import axios from 'axios'
// import { TRIP_FREQUENCIES } from './trip_frequencies'

const VUE_APP_API_BASE_URL = process.env.VUE_APP_API_BASE_URL || ''
console.log("*TCL: VUE_APP_API_BASE_URL", VUE_APP_API_BASE_URL)
console.log("*TCL: process.env.VUE_APP_API_BASE_URL", process.env.VUE_APP_API_BASE_URL)

export const fetchFeedback = async (options = {}) => {
  try {
    let url = VUE_APP_API_BASE_URL + '/api/feedback'
    const response = await axios.get(url, options);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}

export const fetchFeedbackLog = async (options = {}) => {
  console.log("fethFeedbackLog -> options", options)
  try {
    let url = VUE_APP_API_BASE_URL + `/api/feedback/${options.feedback_id}/logs`
    const response = await axios.get(url, options);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}


export const updateFeedback = async (data) => {
  try {
    let url = VUE_APP_API_BASE_URL + `/api/feedback/edit/${data.feedback_id}`
    const response = await axios.post(url, data);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}


export const deleteFeedback = async (data) => {
  console.log("deleteFeedback -> feedback_id****", data.feedback_id)
  try {
    let url = VUE_APP_API_BASE_URL + `/api/feedback/${data.feedback_id}`
    const response = await axios.delete(url);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}


export const fetchUsers = async (options = {}) => {
  try {
    let url = VUE_APP_API_BASE_URL + '/api/users'
    const response = await axios.get(url, options);
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error_message)
    } else {
      throw new Error(error)
    }
  }
}